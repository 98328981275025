<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <h1 id="traffic" class="card-title mb-0">Acredius Application</h1>
            <div><p>Please select menu on the left to start</p></div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>

export default {
  name: 'Dashboard'
}
</script>
